var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-order-config-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/professional-relation" } },
                      [_vm._v("工单设置")]
                    ),
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("服务配置")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { staticClass: "table-query-card", attrs: { title: "服务查询" } },
            [
              _c("a-input-search", {
                staticClass: "page-table-search__input",
                attrs: { placeholder: "请输入名称", "allow-clear": "" },
                model: {
                  value: _vm.queryModel.keyWords,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryModel, "keyWords", $$v)
                  },
                  expression: "queryModel.keyWords",
                },
              }),
            ],
            1
          ),
          _c(
            "a-card",
            { attrs: { title: "服务列表" } },
            [
              _c(
                "jtl-button",
                {
                  staticClass: "table-header-button",
                  attrs: { slot: "extra", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.addClick(new _vm.WorkOrderConfigEntityModel())
                    },
                  },
                  slot: "extra",
                },
                [_vm._v("+ 添加")]
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": _vm.TableRowKey,
                  size: _vm.TableSize,
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "enabled",
                    fn: function (text, record) {
                      return [
                        _c("span", {
                          class: "status-circle circle-" + record.active,
                        }),
                        _vm._v(
                          "  " + _vm._s(record.active ? "启用" : "未启用") + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              disabled: !record.canUpdate,
                              title: "确认更新?",
                              "ok-text": "确认",
                              "cancel-text": "取消",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.deployClick(record)
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "jtl-edit-link",
                                class: { disabled: !record.canUpdate },
                              },
                              [_vm._v("更新")]
                            ),
                          ]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.configClick(record)
                              },
                            },
                          },
                          [_vm._v("配置")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.editClick(record)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "确认删除?",
                              "ok-text": "确认",
                              "cancel-text": "取消",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleteClick(record)
                              },
                            },
                          },
                          [
                            _c("a", { staticClass: "jtl-del-link" }, [
                              _vm._v("删除"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }